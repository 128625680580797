import * as firebase from "firebase";

const firebaseConfig = {
	apiKey: "AIzaSyAkwj7gCrYLa17fTKyg5mYymbN9Y-3rtDg",
	authDomain: "fast-in-transit.firebaseapp.com",
	databaseURL: "https://fast-in-transit-default-rtdb.firebaseio.com",
	projectId: "fast-in-transit",
	storageBucket: "fast-in-transit.appspot.com",
	messagingSenderId: "702731676615",
	appId: "1:702731676615:web:aeb0a04e623159e26787e2"
  };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
const storage = firebase.storage();

export { firestore, storage };
